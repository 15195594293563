
import { defineComponent } from "vue";
import { Configuration, OpenAIApi } from "openai";
import { ChatCompletionRequestMessage } from "openai";

export default defineComponent({
  data() {
    return {
      reply: "",
      input: "",
      status: "",
      isRecording: false,
      showReply: false,
      utteranceRate: 1.0,
      utteranceVolume: 0.8,
      utterancePitch: 1.5,
      utteranceVoiceIndex: 0,
      mode: {} as {
        text: string;
        index: number;
        message: ChatCompletionRequestMessage;
      },
      modes: [
        {
          text: "Guess an Item",
          index: 0,
          message: {
            role: "user",
            content: `
              You can think of a very simple everyday item right now (e.g., car, balloon etc.). You can't change this item during our play unless I successfully guessed it. 

              Then I will start to ask yes or no questions about the item, you will give yes or no answer. If I successfully guessed that item, this game will end at which time you need to explicitly congratulate me and tell me the total number of guesses and tell me the item you generated at the very beginning.

              During the play please also keep record of the number of my guesses (i.e., questions) and output the total number of guesses after I made each guess.
            `,
          },
        },
        {
          text: "Play Math",
          index: 0,
          message: {
            role: "user",
            content: `
              alternatively provide some simple math problems. 
            `,
          },
        },
        {
          text: "Interstellar",
          index: 0,
          message: {
            role: "user",
            content: `
              chat about movie interstellar 
            `,
          },
        },
        {
          text: "dragonball",
          index: 0,
          message: {
            role: "user",
            content: `
              chat about the commic series dragonball
            `,
          },
        },
        {
          text: "limited language speaking model",
          index: 0,
          message: {
            role: "system",
            content: `
              you need to strictly control the words in your reponses. In your answer, about 75% words should be choosen from the following set:
              [you]
            `,
          },
        },
      ],
      messages: [
        {
          role: "system",
          content: `You are a language trainer for young pupil. Use short reply (around 50 words) and simple sentences during the conversation`,
        },
      ] as ChatCompletionRequestMessage[],
    };
  },
  components: {
    // VueSpeechRecognition,
    // VueSpeechSynthesis
  },
  // mounted() {},
  watch: {
    // whenever question changes, this function will run
    mode(newMode) {
      this.messages.splice(1);
      this.messages.push(this.mode.message);
      console.log(`change mode: ${newMode.text}`);
    },
  },
  methods: {
    /* JS comes here */
    async runSpeechRecognition() {
      // get output div reference
      var output = document.getElementById("output");
      // get action element reference
      var action = document.getElementById("action");
      // new speech recognition object
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const recognition = new window.webkitSpeechRecognition();
      // var SpeechRecognition = SpeechRecognition;
      // var recognition = new SpeechRecognition();

      // This runs when the speech recognition service starts
      recognition.onstart = function () {
        if (action)
          action.innerHTML = `<small>listening, please speak...</small>`;
        console.log("starts");
      };

      recognition.onspeechend = function () {
        if (action) action.innerHTML = "<small></small>";
        recognition.stop();
        console.log("stops");
      };

      // This runs when the speech recognition service returns result
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const self = this;
      recognition.onresult = async function (event: {
        results: { transcript: string; confidence: number }[][];
      }) {
        var transcript = event.results[0][0].transcript;
        var confidence = event.results[0][0].confidence;
        if (output) {
          output.innerHTML =
            "<b>Text:</b> " +
            transcript +
            "<br/> <b>Confidence:</b> " +
            confidence * 100 +
            "%";
          if (this.showReply) output.classList.remove("hide");
        }
        // pass it to the input then transfer to GPT API
        self.input = transcript;
        self.messages.push({
          role: "user",
          content: transcript,
        });
        await self.chat();
      };

      // start recognition
      recognition.start();
    },

    async chat() {
      console.log(process.env.VUE_APP_OPENAI_API_KEY);
      // Set up the OpenAI API client
      const configuration = new Configuration({
        apiKey: process.env.VUE_APP_OPENAI_API_KEY, //process.env.VUE_APP_OPENAI_API_KEY
      });
      const openai = new OpenAIApi(configuration);

      // // Call the chat API and wait for the response
      // const response = await openai.createCompletion({
      //   model: "text-davinci-003",
      //   prompt: this.input,
      //   max_tokens: 100,
      //   temperature: 0.7,
      //   // top_p: 1,
      //   // stop: "\n",
      // });

      // // Extract the chat response from the API response
      // if (response.data.choices[0].text) {
      //   this.reply = response.data.choices[0].text;
      // }

      // turbo-3.5
      const response = await openai.createChatCompletion({
        model: "gpt-3.5-turbo",
        messages: this.messages,
      });

      // Extract the chat response from the API response
      this.reply = response.data?.choices[0]?.message?.content || "";
      if (this.reply) {
        this.messages.push({
          role: "assistant",
          content: this.reply,
        });
      }

      // speech synthesis
      if ("speechSynthesis" in window) {
        var synthesis = window.speechSynthesis;

        // Get the first `en` language voice in the list
        var voice = synthesis.getVoices().filter(function (voice) {
          return voice.lang === "en";
        })[0];

        // Create an utterance object
        var utterance = new SpeechSynthesisUtterance(this.reply);

        // Set utterance properties
        utterance.voice = voice;
        utterance.pitch = this.utterancePitch;
        utterance.rate = this.utteranceRate;
        utterance.volume = this.utteranceVolume;

        // Speak the utterance
        synthesis.speak(utterance);
      } else {
        console.log("Text-to-speech not supported.");
      }
      console.log(this.reply);
    },
  },
});
